import { ViewerModel, CookieAnalysis, WixBiSession } from '@wix/thunderbolt-symbols'
import isBot from './isBot'
import isSuspectedBot from './isSuspectedBot'
import isIFrame from './isIFrame'

const SITE_TYPES: Record<ViewerModel['site']['siteType'], WixBiSession['st']> = {
	WixSite: 1,
	UGC: 2,
	Template: 3,
}

const extractServerTiming = () => {
	let serverTiming: Array<any>
	try {
		serverTiming = performance.getEntriesByType('navigation')[0].serverTiming || []
	} catch (e) {
		serverTiming = []
	}
	let microPop
	const matches: Array<string> = []
	serverTiming.forEach((st) => {
		switch (st.name) {
			case 'cache':
				matches[1] = st.description
				break
			case 'varnish':
				matches[2] = st.description
				break
			case 'dc':
				microPop = st.description
				break
			default:
				break
		}
	})
	return {
		microPop,
		matches,
	}
}

const extractCookieData = (): CookieAnalysis => {
	let microPop,
		caching = 'none'
	let match = document.cookie.match(
		/ssr-caching="?cache[,#]\s*desc=(\w+)(?:[,#]\s*varnish=(\w+))?(?:[,#]\s*dc[,#]\s*desc=(\w+))?(?:"|;|$)/
	)
	if (!match && window.PerformanceServerTiming) {
		const results = extractServerTiming()
		microPop = results.microPop
		match = results.matches
	}
	if (match && match.length) {
		caching = `${match[1]},${match[2] || 'none'}`
		if (!microPop) {
			microPop = match[3]
		}
	}
	if (caching === 'none') {
		const timing = performance.timing
		if (timing && timing.responseStart - timing.requestStart === 0) {
			caching = 'browser'
		}
	}
	return {
		caching,
		isCached: caching.indexOf('hit') === 0,
		...(microPop ? { microPop } : {}),
	}
}

export default (): WixBiSession => {
	const {
		fedops,
		viewerModel: { siteFeaturesConfigs, requestUrl, site, fleetConfig, requestId, commonConfig },
	} = window

	const btype = isBot(window) || isIFrame() || isSuspectedBot() || siteFeaturesConfigs.seo.isInSEO

	return {
		suppressbi: requestUrl.includes('suppressbi=true'),
		initialTimestamp: window.initialTimestamps.initialTimestamp,
		initialRequestTimestamp: window.initialTimestamps.initialRequestTimestamp,
		viewerSessionId: fedops.vsi,
		siteRevision: String(site.siteRevision),
		msId: site.metaSiteId,
		is_rollout: fleetConfig.code === 0 || fleetConfig.code === 1 ? fleetConfig.code : null,
		is_platform_loaded: 0,
		requestId,
		requestUrl: encodeURIComponent(requestUrl),
		sessionId: String(site.sessionId),
		btype,
		isjp: !!btype,
		dc: site.dc,
		siteCacheRevision: '__siteCacheRevision__',
		checkVisibility: (() => {
			let alwaysVisible = true

			function checkVisibility() {
				alwaysVisible = alwaysVisible && document.hidden !== true
			}

			document.addEventListener('visibilitychange', checkVisibility, { passive: true })
			checkVisibility()
			return () => {
				checkVisibility()
				return alwaysVisible
			}
		})(),
		...extractCookieData(),
		isMesh: 1,
		isServerSide: 0,
		st: SITE_TYPES[site.siteType] || 0,
		commonConfig,
	}
}
